@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --swiper-pagination-color: #858e9b;
  --swiper-pagination-bullet-inactive-color: #858e9b40;
}

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.swiper-pagination-bullet-active {
  scale: 1.2;
}

body {
  font-family: "Manrope", sans-serif;
  background: #181a20;
}

.PhoneInputInput {
  background: none !important;
  outline: none;
}

/* one 38 */
/* two 33 */
/* three 67 */
.rc-slider-captcha-jigsaw-puzzle {
  /* height: 60px !important;
  width: 64px; */
  /* bottom: 38px; */
  /* transform: scale(0.9); */
}

.puzzleOne .rc-slider-captcha-jigsaw-puzzle {
  bottom: 38px;
}

.puzzleTwo .rc-slider-captcha-jigsaw-puzzle {
  bottom: 34px;
  transform: scale(1.12);
}

.puzzleThree .rc-slider-captcha-jigsaw-puzzle {
  bottom: 68px;
}

.puzzleFour .rc-slider-captcha-jigsaw-puzzle {
  bottom: 28.4px;
}

.rc-slider-captcha-jigsaw-bg {
}

.Toastify__progress-bar-theme--dark {
  background: #fad998 !important;
}

.Toastify__toast-theme--dark {
  background: #202020 !important;
}

::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #181a20;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #858e9b;
  border-radius: 3px;
}

.bg-gradient {
  /* background: linear-gradient(
    180deg,
    #fcdb9a 12.5%,
    #fad998 25%,
    #f3d292 37.5%,
    #e9c687 50%,
    #d9b578 62.5%,
    #c59f64 75%,
    #ac854c 87.5%,
    #997039 100%
  ); */
  background: #fcdb9a;
  background: linear-gradient(
    180deg,
    #fcdb9a 0%,
    #fad998 20%,
    #f3d292 40%,
    #e9c687 45%,
    #d9b578 50%,
    #c59f64 60%,
    #ac854c 70%,
    #997039 90%
  );
  background: -webkit-linear-gradient(
    180deg,
    #fcdb9a 0%,
    #fad998 20%,
    #f3d292 40%,
    #e9c687 45%,
    #d9b578 50%,
    #c59f64 60%,
    #ac854c 70%,
    #997039 90%
  );
  background: -moz-linear-gradient(
    180deg,
    #fcdb9a 0%,
    #fad998 20%,
    #f3d292 40%,
    #e9c687 45%,
    #d9b578 50%,
    #c59f64 60%,
    #ac854c 70%,
    #997039 90%
  );
  box-shadow: 120px 0px 305px -183px rgba(255, 191, 63, 0.41);
  -webkit-box-shadow: 120px 0px 305px -183px rgba(255, 191, 63, 0.41);
  -moz-box-shadow: 120px 0px 305px -183px rgba(255, 191, 63, 0.41);
}

.PhoneInputCountrySelect {
  background: #232324 !important;
}

.PhoneInputCountrySelect option:hover {
  background: #fad998 !important;
}

.coin-marquee-header {
  display: none;
}

.coin-marquee-item {
  pointer-events: none;
}

.coin-marquee-wrapper--dark {
  background: #232324;
}

.coin-marquee-container {
  width: 100%;
}

.coin-marquee-container__inner {
  animation-duration: 60s !important;
}

.PhoneInputCountry {
  display: none !important;
}

input:disabled {
  background: none;
}

.bg-black {
  background-color: transparent !important;
}
